import AddressControl from 'src/components/FullPatientData/PatientWidgetBody/AddressControlUpdated/AddressControlUpdated';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddressControl,
  },
};
